.info-container {
  background-color: $gray-f;
  padding: 5rem 0 6rem;
  margin-top: 3rem;
  h2 {
    padding-bottom: 2rem;
  }
  .steps {
    display: flex;
    justify-content: space-evenly;

    .middle {
      margin: 0 3rem;
    }

    h3 {
      color: $main-orange;
      margin: 2rem 0;
    }
    img {
      max-width: 36px;
    }
  }
}

@media (max-width: 600px) {
  .info-container {
    margin-top: 6rem;
    .steps {
      flex-direction: column;
      .middle {
        margin: 3rem 0;
      }
    }
  }
}
