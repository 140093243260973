.credits {
  width: 32%;
  padding: 0.5rem 0;
  margin-top: 5rem;
  .gray-background {
    background-color: $gray-f;
    padding: 3.5rem;
    margin-bottom: 5rem;
    border-radius: 1rem;
    h3 {
      margin-bottom: 2rem;
    }
  }
  span {
    font-size: 1.8rem;
  }
  .potential-earnings {
    p {
      color: $gray-b;
      margin-top: 1rem;
    }
    span {
      color: $main-blue;
      font-weight: 700;
    }
    .strong {
      margin-top: 4rem;
    }
  }
}

@media (max-width: 600px) {
  .credits {
    width: 100%;
    margin-top: 2rem;
  }
}
