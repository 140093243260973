.form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #000000;
  padding: 5rem;
  margin: 0;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.11);
  h3 {
    text-align: center;
    margin-bottom: 5rem;
  }
  img {
    max-width: 36px;
  }
  button {
    font-size: 1.8rem;
    font-weight: 700;
    background: $main-gradient;
    height: 40px;
    min-width: 244px;
    padding: 1rem 2rem;
    align-self: center;
  }
  button:disabled,
  button[disabled] {
    background: $disabled-gradient;
  }
}

.field-image-container {
  display: flex;
  margin-bottom: 4rem;
  align-self: center;
}

.step-image {
  label {
    display: none;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  label {
    font-size: $label-font;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  label,
  input,
  select,
  textarea {
    width: 52vw;
  }
  input,
  select {
    height: 50px;
  }
  textarea {
    font-size: 1.6rem;
    resize: none;
    height: 144px;
    border: 1px solid $gray-d;
    border-radius: 0.6rem;
    padding: 1.5rem 1rem;
  }
}

.select-container {
  position: relative;
}

.select-icon {
  position: absolute;
  top: 40%;
  right: 5%;
  width: 10px;
  height: 6px;
}

@media (max-width: 600px) {
  .field-image-container {
    flex-direction: column;
  }
  .step-image {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    label {
      display: inline-block;
      font-size: $label-font;
      margin-left: 1rem;
    }
  }
  .form-field {
    display: block;
    label {
      display: none;
    }
    input,
    select,
    textarea {
      width: 72vw;
    }
  }
}
