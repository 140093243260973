.error404 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  padding: 3rem 0 5rem;

  img {
    max-height: 65vh;
  }

  .error-text {
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 5rem;
      margin-top: 5rem;
    }
    p {
      margin: 3rem 0;
      font-size: 2rem;
    }

    button {
      background: $main-gradient;
      min-width: 180px;
      width: 180px;
      max-width: 180px;
      margin-top: 1rem;
      a {
        color: #ffffff;
        font-weight: 700;
      }
      a:hover,
      a:focus {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 800px) {
  header {
    display: none;
  }

  .error404 {
    flex-direction: column;
    align-items: center;
    width: 100%;

    .error-text {
      align-items: center;
      h3 {
        font-size: 3rem;
        text-align: center;
      }
      p {
        font-size: $p-font;
        text-align: center;
      }
    }
  }
}
