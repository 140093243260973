.earn {
  padding: 6rem 0 3rem;
}

h2 {
  margin-bottom: 2rem;
}

p {
  color: $gray-b;
  line-height: 2.6rem;
}

strong {
  color: $gray-a;
}

.email-or-phone,
.input-clone {
  margin-right: 0.8rem;
}

.contacts-email-input-container {
  display: flex;
  flex-direction: column;
  .error-container {
    .error-handler {
      width: 100%;
    }
  }
}

.contacts-container {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.contact-container {
  display: flex;
  align-items: center;
  background-color: $gray-f;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
}

.contact {
  margin-right: 1rem;
}

.x {
  color: $gray-a;
  font-size: 1.2rem;
  cursor: pointer;
}

.email-input {
  display: flex;
  margin-bottom: 2rem;
  // height: 7vh;
  .email-or-phone {
    height: 50px;
  }
  button {
    background: $main-gradient;
    font-weight: 700;
  }
  button:disabled,
  button[disabled] {
    background: $disabled-gradient;
  }
}

@media (max-width: 1000px) {
  .email-input {
    flex-direction: column;
    .email-or-phone {
      width: 100%;
    }
    button {
      height: 40px;
      max-width: 180px;
      margin-top: 2.4rem;
      font-size: 1.6rem;
    }
  }
}
