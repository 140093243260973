// font
$font-family: 'DM Sans', sans-serif;
$primary-text-color: #1c1c1e;
$secondary-text-color: #ffffff;

// font size
$header-font-s: 1.4rem;
$header-font-m: 1.8rem;
$header-font-l: 2rem;
$h1-font: 5rem;
$h2-font: 2.8rem;
$h3-font: 2.2rem;
$p-font: 1.6rem;
$p-font-l: 2.6rem;
$label-font: 1.8rem;

// responsive
$h1-responsive: 3.2rem;
$p-responsive: 2.4rem;
$span-responsive: 1.4rem;

// buttons
$button-font-size: 1.8rem;

// color scheme
$dark-blue: #162947;
$main-blue: #4d7bd7;
$main-pink: #dc659c;
$main-orange: #f18659;

$main-gradient: linear-gradient(-90deg, #dc659c 0%, #f18659 100%);

$disabled-gradient: linear-gradient(90deg, hsla(18, 84%, 65%, 0.7) 0%, hsla(332, 63%, 63%, 0.7) 100%);

// grays
$gray-a: #1c1c1e;
$gray-b: #5d6169;
$gray-c: #aaafb7;
$gray-d: #d7dae0;
$gray-e: #e6e8ec;
$gray-f: #f6f7f9;

// container width
$container-width: 84%;
