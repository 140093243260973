.content-container {
  display: flex;
  justify-content: space-between;
}

.how-to-share {
  display: flex;
  flex-direction: column;
  width: 60%;
}

@media (max-width: 600px) {
  .content-container {
    flex-direction: column;
  }

  .how-to-share {
    width: 100%;
  }
}
