@import './partials/variables';
@import './partials/header';
@import './partials/share';
@import './partials/banner';
@import './partials/epinvite';
@import './partials/socialinvite';
@import './partials/credits';
@import './partials/info';
@import './partials/form';
@import './partials/404';
@import './partials/errors';

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
}
body {
  margin: 0;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
figcaption,
figure,
main {
  display: block;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details,
menu {
  display: block;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
[hidden] {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;
  a {
    text-decoration: none;
  }
}

*:focus,
*:visited,
*:active,
*:hover {
  outline: 0 !important;
}
*::-moz-focus-inner {
  border: 0;
}

body {
  // margin: 0;
  // reset font size to 10px
  font-size: 1rem;
  color: $primary-text-color;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

p {
  font-size: $p-font;
}

h2 {
  font-size: $h2-font;
}

h3 {
  font-size: $h3-font;
}

button {
  min-width: 16vw;
  min-height: 5vh;
  font-size: 1.4rem;
  font-weight: 500;
  color: #ffffff;
  white-space: nowrap;
  border: none;
  border-radius: 0.6rem;
  cursor: pointer;
}

input,
select,
.input-clone {
  height: 50px;
  color: $gray-a;
  font-size: 1.6rem;
  padding: 0 1rem;
  border: 1px solid $gray-d;
  border-radius: 0.6rem;
}

input,
select {
  width: 500px;
}

.input-clone {
  width: 700px;
}

input::placeholder {
  color: $gray-c;
}

input:hover,
input:focus,
select:hover,
select:focus {
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.flex {
  display: flex;
}

.container {
  width: $container-width;
  margin: 0 auto;
}

.vertical-padding {
  padding: 4rem 0;
}

.space-between {
  justify-content: space-between;
}

.gray-background {
  background-color: $gray-f;
}
