.error-handler {
  display: flex;
  align-items: center;
  width: calc(2rem + 36px + 52vw);
  align-self: center;
  font-size: $p-font;
  margin-bottom: 3rem;
  padding: 0.5rem;
  border-radius: 6px;
}

.isa_info {
  color: #00529b;
  background-color: #bde5f8;
  z-index: 10;
}
.isa_success {
  color: #4f8a10;
  background-color: #c9f8be;
  z-index: 10;
}
.isa_warning {
  color: #ca8516;
  background-color: #fdf7ed;
  z-index: 10;
}
.isa_error {
  color: #ec2626;
  background-color: #ffd2d2;
  z-index: 10;
}
.isa_info i,
.isa_success i,
.isa_warning i,
.isa_error i {
  margin: 1rem 1.5rem 1rem 1rem;
  font-size: 1.6rem;
  vertical-align: middle;
  cursor: pointer;
}

@media (max-width: 600px) {
  .error-handler {
    width: 69vw;
    padding: 1.5rem 1rem;
  }
}
