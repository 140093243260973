.social-invite {
  margin-bottom: 3rem;
  h3 {
    margin: 2rem 0 2.2rem;
  }
}

.social-flex {
  display: flex;
  align-items: center;
}

.input-clone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referral-link {
  display: flex;
  align-items: center;
  color: $gray-b;
  height: 7vh;
}
.copy {
  margin-left: 2rem;
}

.copy,
.copy-clone {
  color: $main-blue;
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
  i {
    font-size: 1.4rem;
    margin-left: 0.5rem;
  }
}

.copy:hover,
.copy:focus,
.copy-clone:hover,
.copy-clone:focus {
  text-decoration: underline;
}

.copy-clone {
  display: none;
  // width: 12%; //!Works on desktop but not on Mobile for the copy issue
}

// ! <---------- on copy animation ---------->
.copy,
.copy-clone {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
}

.copy:before,
.copy-clone:before,
.copy:after,
.copy-clone:after {
  position: absolute;
  content: '';
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}

.copy:before,
.copy-clone:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, #dc659c 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #162947 20%, transparent 30%),
    radial-gradient(circle, #f18659 20%, transparent 20%), radial-gradient(circle, #4d7bd7 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #dc659c 15%, transparent 20%),
    radial-gradient(circle, #dc659c 20%, transparent 20%), radial-gradient(circle, #dc659c 20%, transparent 20%),
    radial-gradient(circle, #4d7bd7 20%, transparent 20%), radial-gradient(circle, #e9b255 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}

.copy:after,
.copy-clone:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, #dc659c 20%, transparent 20%),
    radial-gradient(circle, #162947 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #f18659 15%, transparent 20%),
    radial-gradient(circle, #dc659c 20%, transparent 20%), radial-gradient(circle, #dc659c 20%, transparent 20%),
    radial-gradient(circle, #4d7bd7 20%, transparent 20%), radial-gradient(circle, #e9b255 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.copy.animate:before,
.copy-clone.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}

.copy.animate:after,
.copy-clone.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
// ! <---------- on copy animation ---------->

.social-share {
  a,
  .middle-icon {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    img {
      max-width: 100%;
    }
    button {
      display: flex;
    }
  }
  a:hover,
  a:focus,
  img:hover,
  img:focus {
    outline: none;
  }
  .middle-icon {
    margin: 0 0.5rem;

    button {
      max-width: 3rem;
      min-width: 3rem;
      height: 3rem;
      max-height: 3rem;
      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .social-invite {
    margin-top: 0;
  }
}

@media (max-width: 1000px) {
  .social-invite {
    margin-top: 2rem;
  }
  .social-flex {
    .input-clone {
      max-width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .social-flex {
    flex-direction: column;
    align-items: flex-start;
    .input-clone {
      width: 100%;
      margin-bottom: 1.5rem;
      overflow: hidden;
    }
  }
  .copy {
    visibility: hidden;
  }
  .copy-and-social {
    display: flex;
    align-items: center;
    .copy-clone {
      display: block;
      margin-right: 1.5rem;
    }
  }
}
