.share {
  background-image: url('../../assets/ShareBanner.png');
  background-size: cover;
  max-height: 43vh;
  height: 43vh;
  display: flex;
  align-items: center;
  .share-banner {
    h1 {
      font-size: 3.6rem;
    }
  }
}

.book,
.new {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.book {
  background-image: url('../../assets/FormBanner.png');
  background-size: cover;
}

.new {
  background-image: url('../../assets/newformbanner.png');
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.white {
  color: #ffffff;
}

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: $container-width;
  margin: 0 auto;

  // height: 56vh;
  span {
    color: $dark-blue;
    text-transform: uppercase;
    font-size: $header-font-m;
    letter-spacing: 0.2rem;
  }
  h1 {
    color: $secondary-text-color;
    font-size: $h1-font;
  }
  h1.book {
    width: 69vw;
    align-self: center;
  }
  p {
    color: $secondary-text-color;
    font-size: $p-font-l;
    line-height: 1.25;
    margin: 3rem 0 6rem;
  }
  button {
    font-size: 1.8rem;
    background-color: $dark-blue;
    min-width: 244px;
    height: 40px;
    max-height: 40px;
    align-self: center;

    a {
      color: #ffffff;
      font-weight: 700;
    }
    a:hover,
    a:focus {
      text-decoration: none;
    }
  }
}

@media (max-width: 600px) {
  header {
    height: 0;
    visibility: hidden;
  }

  .book {
    background-image: url('../../assets/MobileJoinBanner.png');
    background-size: cover;
    // height: 594px;
    height: 100vh;
    .book-banner {
      span {
        font-size: $span-responsive;
      }
      h1 {
        font-size: $h1-responsive;
      }
      p {
        font-size: $p-responsive;
      }
    }
  }

  .share {
    background-image: url('../../assets/MobileShareBanner.png');
    background-position: 50% 100%;
    background-size: contain;
    height: 403px;
  }
}

@media (max-width: 800px) {
  .banner {
    h1 {
      font-size: 4rem;
    }
  }
}
