.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: $main-gradient;
  color: $secondary-text-color;
  height: 57px;
  .nft-info {
    display: flex;
    justify-content: space-between;
    // align-items: center;
  }

  .call,
  .email {
    a {
      color: $secondary-text-color;
      font-size: $header-font-s;
      font-weight: 700;
    }
    i {
      font-size: $header-font-s;
      font-weight: 900;
      margin-right: 10px;
    }
  }

  .email {
    padding-left: 20px;
    // height: 28px;
  }

  .g-star-holder {
    color: #ffffff;
    i {
      font-size: $header-font-s;
      margin-right: 4px;
    }
    .fa-google {
      margin-right: 10px;
    }
  }
}

a:hover {
  text-decoration: underline;
}

header {
  section.container {
    align-items: center;
    height: 105px;
  }
}

.logo {
  width: 207px;
  img {
    max-width: 100%;
  }
}

.phone-dropdown-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $main-blue;
  font-size: $header-font-l;
  width: 253px;
  height: 65px;
  padding-right: 25px;
  a {
    color: $main-blue;
    font-weight: 700;
  }
}

.phone-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60%;
  height: 0;
  width: 0;
  overflow: hidden;
  :first-child {
    padding-top: 20px;
  }
  a {
    font-size: 1.6rem;
    font-weight: 700;
    color: $gray-a;
    padding: 10px;
  }
}

.phone-dropdown-container:hover .phone-options {
  height: 155px;
  width: 255px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  transform: translateX(-25px) translateY(13px);
  padding: 10px 25px;
}

.phone,
.phone-options {
  a {
    padding-left: 10px;
  }
}

@media (max-width: 700px) {
  .nft-info {
    flex-direction: column;
    .g-star-holder {
      margin-top: 6px;
    }
  }
}
